import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import MainLayout from "../componentsTailwind/MainLayout";
import { Container, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";

const ElectricityBonus404Page = () =>
{
  return (
    <MainLayout>
      <Helmet>
        <title>Je nám líto, ale tato stránka již není aktivní
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrapper backgroundColor="white">
        <Container px={["0px", null, "15px"]} pt={"40px"} pb={"100px"}>
          <Heading
            as="h1"
            width="100%"
            fontWeight="bold"
            fontSize={["26px", null, "36px"]}
            lineHeight={["36px", null, "50px"]}
            textAlign="left"
            color="green.dark"
            marginBottom="20px"
          >
            Je nám líto, ale tato stránka již není aktivní…
          </Heading>
          <Text fontSize="14px" color="grey.dark" fontWeight="400" pb="14px">
            Nabídka byla časově limitovaná a její platnost skončila.
          </Text>
          <Text fontSize="14px" color="grey.dark" fontWeight="400" pb="14px">
            Určitě to ale nevzdávejte a vyberte si z naší aktuální nabídky výhodných energií přechodem na <Link to="/">hlavní stránku</Link>.
          </Text>
        </Container>
      </Wrapper>
    </MainLayout >
  );
};

export default ElectricityBonus404Page;
